import Dropdown from '../../components/other/NDropdown/Dropdown';
import LineTabs from '../../components/other/NLineTabs/LineTabs';
import Tabs from '../../components/other/NTabs/Tabs';
// import CellInput from '../../components/form/NCellInput/CellInput';
import CellSelect from '../../components/form/NCellSelect/CellSelect';
import Popup from '../../components/other/NPopup/Popup';
import Api from '../../requests/Api';
import './mainLayout.scss';



(function () {
    document.addEventListener('DOMContentLoaded', function () {
        const $language = document.querySelector('.js-language-dropdown');
        const $languageContent = document.querySelector('.js-language-dropdown-content');
        const $lineTabs = document.querySelectorAll('.js-line-tabs');
        const $dropdownList = document.querySelectorAll('.js-dropdown-menu');
        const $tabs = document.querySelectorAll('.js-tabs-menu');
        // const $cellInput = document.querySelector('.js-cell');
        const $cellSelect = document.querySelectorAll('.js-cell-select');
        const $cellHeader = document.querySelector('.js-cell-select-header');
        const $cellCurrent = document.querySelector('.js-cell-select-current');
        const $cellBody = document.querySelector('.js-cell-select-body');
        const $cellIcon = document.querySelector('.ns-cell-select__icon');
        const $menuButton = document.querySelector('.js-menu-button');
        const $menuId = document.getElementById('menu__toggle');
        const $footerbtn = document.getElementById('secondSpecialButton');
        const $headerbtn = document.getElementById('specialButton');
        const $menuContent = document.querySelector('.menu__content');
        const $popupSearch = document.querySelector('#popup-search');
        const $popupResult = document.querySelector('.js-popup-result');
        const $mobileHelper = document.querySelector('.js-m-helper');
        const $mobileOpenHelper = document.querySelector('.js-open-helper');
        const $popup = document.querySelector('.js-popup');
        const $body = document.querySelector('body');
        const $Api = new Api();


        new Popup($popup);
        $tabs.forEach((tab) => {
            new Tabs(tab);
        });
        const bodyActive = 'position: fixed; overflow-y: hidden; width: 100%; height: 100%; top: 0; left: 0; z-index: 9999;';

		$mobileHelper.addEventListener('click', function () {
			$mobileOpenHelper.classList.toggle('open');
        });

        $language.addEventListener('click', function () {
            $languageContent.classList.toggle('active');
        });

		$footerbtn.addEventListener('click', function () {
			$headerbtn.click()
        });

        window.addEventListener('click', function (event) {
            if (event.target !== $language) {
                $languageContent.classList.remove('active');
            }
            if (event.target !== $cellBody && event.target !== $cellHeader && event.target !== $cellCurrent && event.target !== $cellIcon) {
                $cellBody.style.display = 'none';
            }
        });
        // $popupSearch.addEventListener('input', () => {
        // 	// $popupResult.classList.add('active');
        // 	// const html =
        // 	//     '<h6>Продукты для физлиц</h6>\n<ul class="u-clear-fix b-header-main__list">\n    <li>\n                <a href="/policy/strahovanie_jizni_kredit" class="js-header-product-link">\n                    <span class="b-link"><span class="b-link__text">Страхование заемщика потребительского кредита</span></span>\n                </a>\n            </li><li>\n                <a href="/policy/Credit_card" class="js-header-product-link">\n                    <span class="b-link"><span class="b-link__text">Страхование владельцев кредитных карт</span></span>\n                </a>\n            </li><li>\n                <a href="/policy/ipoteka_life" class="js-header-product-link">\n                    <span class="b-link"><span class="b-link__text">Ипотечное страхование жизни «Защищенный заемщик»</span></span>\n                </a>\n            </li><li>\n                <a href="/policy/setelem" class="js-header-product-link">\n                    <span class="b-link"><span class="b-link__text">Страхование заемщиков кредитов &quot;Драйв Клик Банк&quot; (до 09.12.22 &quot;Сетелем Банк&quot;)</span></span>\n                </a>\n            </li></ul>\n<h6><a href="#" class="js-header-product-link js-all-result-show">Все результаты</a></h6>\n';
        // 	// $popupResult.innerHTML = html;
        // 	$popupResult.innerHTML = '';
        // 	$Api.getSearchResult($popupSearch.value).then((res) => {
        // 		if (res.result === 'ok') {
        // 			$popupResult.innerHTML = res.html;
        // 		} else {
        // 			console.log('Серверная ошибка');
        // 		}
        // 	});
        // });

        // Добавление свойство блокировки прокрутки body при активном меню
        $menuButton.addEventListener('click', toggleMenu);
        //

        $lineTabs.forEach((lineTab) => {
            new LineTabs(lineTab);
        });

        $dropdownList.forEach((dropdown) => {
            new Dropdown(dropdown);
        });

        // new CellInput($cellInput);
        $cellSelect.forEach((selector) => {
            new CellSelect(selector);
        });

        function toggleMenu() {
            if ($menuId.checked === true) {
                // $body.classList.remove('active');
                $body.removeAttribute('style');
            } else {
                $body.setAttribute('style', bodyActive);
                // $body.classList.add('active');
            }
        }
    });
})();
